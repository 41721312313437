import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'The Ultimate Marketing Checklist For Direct Primary Care';
    const desc = `It's not easy to fill your patient panel in DPC. You need to convince patients of both the DPC modal and your own merit as a doctor. Here are some marketing techniques that have helped DPC practices in the past.`;
    return (
      <TodoSubList title={title} desc={desc} id="marketing">
        <Head title={title} description={desc} url="/todo/marketing/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
